import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { TokenService } from "@services";
import jwt_decode from "jwt-decode";

const baseURL = process.env.REACT_APP_SERVER_URL; // Server URL
export const clientURL = process.env.REACT_APP_CLIENT_URL; // Client URL

export const URL_UPLOAD_PROFILE_PHOTO = `${baseURL}/api/v1/storage/profile`;

export enum FETCH_STATUS {
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
  LOADING = "LOADING",
}

export interface IApiResponse<TModel> {
  data: TModel;
  message: string;
  statusCode: number;
}

export interface IApiResponseList<TModel> {
  data: {
    data: TModel[] | undefined;
    total: number;
    success: boolean;
  };
  message: string;
  statusCode: number;
}

const api = {
  call: (customURL = baseURL) => {
    return axios.create({
      baseURL: customURL,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  callWithToken: () => {
    const access_token = TokenService.getLocalAccessToken();
    const axiosInstance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!access_token) return axiosInstance;
    try {
      jwt_decode(access_token);
    } catch (e) {
      TokenService.removeAllLocalTokens();
      return axiosInstance;
    }

    // Added access token
    axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (access_token) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${access_token}`,
          };
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (res: AxiosResponse) => res,
      async (error: AxiosError) => {
        const originalConfig = error.config;
        if (
          originalConfig.url !== "/api/v1/account/sign-in" &&
          error.response
        ) {
          if (
            error.response?.status === 401 &&
            originalConfig.headers &&
            originalConfig.headers["_retry"] !== "true"
          ) {
            originalConfig.headers = {
              ...originalConfig.headers,
              _retry: String(true),
            };

            try {
              console.log(
                "retry with refresh token",
                TokenService.getLocalRefreshToken()
              );
              const rs = await axiosInstance.post(
                "/api/v1/account/refresh-token",
                {
                  refreshToken: TokenService.getLocalRefreshToken(),
                }
              );
              const { accessToken, refreshToken } = rs.data.data;
              TokenService.updateLocalAccessToken(accessToken, refreshToken);
              originalConfig.headers = {
                ...originalConfig.headers,
                Authorization: `Bearer ${accessToken}`,
              };
              const retryInstance = axios.create({
                baseURL: originalConfig.baseURL,
                headers: {
                  ...originalConfig.headers,
                },
              });

              // Retry
              return retryInstance.request(originalConfig);
            } catch (err) {
              TokenService.removeAllLocalTokens();
              return Promise.reject(err);
            }
          } else {
            return Promise.reject(error);
          }
        }
      }
    );

    return axiosInstance;
  },
};

export default api;
